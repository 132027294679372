<template>
    <div>
        <div class="page-title-wrap">
        <h1>Precalificados</h1>
        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-3">
                        <b-card tag="article" class="mb-2 mt-3 ml-1">
                            <b-card-text>
                                <p class="text-start">
                                    Cantidad de Proyectos
                                </p>
                                <hr class="mb-0" />
                                <p class="text-center mt-0" style="font-size: 50px">0</p>
                                <span class="percent"><i class="fa fa-caret-up"></i> 0.00%</span>
                            </b-card-text>
                        </b-card>
                    </div>
                    <div class="col-md-3">
                        <b-card tag="article" class="mb-2 mt-3">
                            <b-card-text>
                                <p class="text-start">
                                    Precalificados
                                </p>
                                <hr class="mb-0" />
                                <p class="text-center mt-0" style="font-size: 50px">0</p>
                                <span class="percent"><i class="fa fa-caret-up"></i> 0.00%</span>
                            </b-card-text>
                        </b-card>
                    </div>
                    <div class="col-md-3">
                        <b-card tag="article" class="mb-2 mt-3">
                            <b-card-text>
                                <p class="text-start">
                                    Vinculados
                                </p>
                                <hr class="mb-0" />
                                <p class="text-center mt-0" style="font-size: 50px">0</p>
                                <span class="percent"><i class="fa fa-caret-up"></i> 0.00%</span>
                            </b-card-text>
                        </b-card>
                    </div>
                    <div class="col-md-3">
                        <b-card tag="article" class="mb-2 mt-3 bg-primary text-white">
                            <b-card-text style="padding: 47px">
                                DASHBOARD POWER BI
                            </b-card-text>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap-container">
            <prequalified-table />
        </div><!--end content-wrap-container-->
    </div>
</template>
<script>

import PrequalifiedTable from './Components/PrequalifiedTable.vue';

export default {
    components:{PrequalifiedTable},
    setup() {
        
    },
}
</script>
<style scoped>
    .percent{
        font-size: 15px;
    }

    .percent i{
        color:#00B150;
        font-size: 20px;
    }
</style>
