<template>
    <div>
        <data-table v-bind="parametersTable1" @actionTriggered="handleAction" />
    </div>
</template>
<script>

export default {
    computed: {
        parametersTable1() {
            return {
                lang: "es",
                data: [
                    {
                        Id: 1,
                        Name: "ALTAGRACIA VASQUEZ PUNTIEL",
                        IdentityCard: "001-0191492-7",
                        BankPrequalificationCertification: "NO",
                        CadastreCertification: "NO",
                        PurchaseOptionContract: "NO",
                        CertificationOfLinkageByTrustee: "NO",
                    },
                ],
                actionMode: "multiple",
                columns: [
                    {
                        key: "Id",
                        title: "No.",
                    },
                    {
                        key: "Name",
                        title: "Nombres y Apellidos",
                    },
                    {
                        key: "IdentityCard",
                        title: "Cédula de Identidad / Pasaporte",
                    },
                    {
                        key: "BankPrequalificationCertification",
                        title: "Certificación de Precalificación Bancaria",
                    },
                    {
                        key: "CadastreCertification",
                        title: "Certificación DE CATASTRO",
                    },
                    {
                        key: "PurchaseOptionContract",
                        title: "Contrato de Opción a Compra",
                    },
                    {
                        key: "CertificationOfLinkageByTrustee",
                        title: "Certificación de Vinculación por Fiduciaria",
                    },
                ],
            };
        },
    },
    setup() {

    },
}
</script>
